html, body {
  height: 100%;
  margin: 0;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
}

.fixed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1; 
}

.navbar-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-wrapper.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar.glass {
  background: rgba(255, 255, 255, 0.2); 
  backdrop-filter: blur(10px);
  padding: 5px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  width: 100%;
}

.navbar-container {
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 20px;
}

.nav-item {
  text-decoration: none;
  color: rgb(100, 120, 150); 
  font-size: 1.1rem; 
  transition: color 0.3s ease;
}

.nav-item:hover {
  color: #19578a; 
}

.nav-item.active {
  font-weight: bold;
  color: #19578a; 
}













.content {
  position: relative;
  z-index: 1;
}

.glass {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 5px;
}

.contentOuter {
  width: 100%;
  margin: auto auto;
  display: flex;
  justify-content: center;
  padding-top:40px;
}

.contentInner {
  max-width: 1920px; 
  width: 100%;
}

.contentCard {
  border-radius: 5px;
  padding:15px;
  height: 100%;
  min-height:250px;
  box-shadow: 
  5px 5px 15px rgba(38, 75, 103, 0.3), 
      -7px -7px 15px rgb(244, 246, 249); 
      transition: all 0.3s ease;
      color: #19578a;
}

.contentCard h2 {
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
    
.contentCard h3 {
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.contentCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentReverseCard {
  border-radius: 5px;
  padding:15px;
  box-shadow: 
      inset 5px 5px 10px rgba(38, 75, 103, 0.2),
      inset -5px -5px 10px rgb(244, 246, 249)  !important;
  transition: all 0.3s ease;
}

.content-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease;
  border: none;
  cursor:pointer;
  padding: 10px 20px;
  color: rgb(100, 120, 150); 
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 5px;
  box-shadow: 
    5px 5px 15px rgba(38, 75, 103, 0.2), 
    -5px -5px 15px rgb(244, 246, 249);
}

.content-button:hover {
  box-shadow: 
    2px 2px 5px rgba(38, 75, 103, 0.2), 
    -2px -2px 5px rgb(244, 246, 249);
  transform: translateY(-2px);
  color: #19578a; 
  /* text-shadow:
  0 0 4px rgba(38, 75, 103, 0.6),
  0 0 6px rgba(38, 75, 103, 0.7),
  0 0 10px rgba(38, 75, 103, 0.9); */
}

.content-button:active {
  box-shadow: 
    inset 2px 2px 5px rgba(38, 75, 103, 0.2),
    inset -2px -2px 5px rgb(244, 246, 249);
  transform: translateY(0);
}





.spline-wrapper {
  height: 100%;
  width: 100%;
  min-height: 250px;
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  overflow: visible; /* Change this line */
}

.spline-container {
  height: 120%;
  width: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%) scale(1.8);
  pointer-events: none; /* Keep this line */
}


















.social-links {
  list-style: none;
  display: flex;
  justify-content: end;
  gap: 15px;
}

.social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent; 
  text-decoration: none;
  transition: all 0.3s ease;
  color: #19578a; 
  box-shadow: 
    5px 5px 15px rgba(38, 75, 103, 0.2), 
    -5px -5px 15px rgb(244, 246, 249);
}

.social-button:hover {
  box-shadow: 
    2px 2px 5px rgba(38, 75, 103, 0.2), 
    -2px -2px 5px rgb(244, 246, 249);
  transform: translateY(-2px);
}

.social-button:active {
  box-shadow: 
    inset 2px 2px 5px rgba(38, 75, 103, 0.2),
    inset -2px -2px 5px rgb(244, 246, 249);
  transform: translateY(0);
}





.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: transparent; 
  text-decoration: none;
  transition: all 0.3s ease;
  color: #19578a; 
  box-shadow: 
    5px 5px 15px rgba(38, 75, 103, 0.2), 
    -5px -5px 15px rgb(244, 246, 249);
}

.contact-button:hover {
  box-shadow: 
    2px 2px 5px rgba(38, 75, 103, 0.2), 
    -2px -2px 5px rgb(244, 246, 249);
  transform: translateY(-2px);
}

.contact-button:active {
  box-shadow: 
    inset 2px 2px 5px rgba(38, 75, 103, 0.2),
    inset -2px -2px 5px rgb(244, 246, 249);
  transform: translateY(0);
}
